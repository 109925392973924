import { defineStore } from 'pinia'
import { WebStorage } from '~/utils/WebStorage'
import type SearchParams from '~/types/search/SearchParams'

const defaultState: SearchParams = {
  is_static_page: false as boolean,
  force_page_change: false as boolean,
  page: 0 as number,
  rows: 24 as number,

  kinderbetten: 0 as number,
  einzelbetten: 0 as number,
  doppelbetten: 0 as number,
  einzelzimmer: 0 as number,
  haustyp: [] as string[],
  ausstattung: [] as number[],
  preis: 0 as number,
  preis_min: 0 as number,
  ort: '' as string,
  von: '' as string,
  bis: '' as string,
  variabilitaet: 0 as number,
  personen: 2 as number,
  onlinebuchung: 0 as number,
  free_cancellation: 0 as number,
  ausstattung_rauchen: [] as string[],
  ausstattung_kueche: [] as string[],
  ausstattung_haustiere: [] as string[],
  bewertung_de: '0' as string,
  order: 'relevanz' as string,
  only_object_ids: [] as string[],
  only_lastminute_objects: false as boolean,
  limits: '' as string,
  urlaub: '' as string,
  serp_highlight: 0 as number
}

const defaultStateDeepClone = function (): SearchParams {
  const params: SearchParams = {}

  for (const key in defaultState) {
    if (!Array.isArray(defaultState[key])) {
      params[key] = defaultState[key]
      continue
    }

    params[key] = []
  }

  return params
}

export const useSearchParamsStore = defineStore('search_params', {
  state: () => {
    const webStorage = new WebStorage()
    const cookieParams = useCookie(
      webStorage.items.SearchParams.getUseCookieKey(),
      webStorage.items.SearchParams.getUseCookieOptions()
    )

    const params: SearchParams = {}

    for (const key in defaultState) {
      params[key] = defaultState[key]
      if (cookieParams?.value?.[key] !== undefined) {
        params[key] = cookieParams.value[key]
      }
    }

    return {
      ...params
    }
  },
  getters: {
    stateDeepClone: function (state): () => SearchParams {
      return (): SearchParams => {
        const params: SearchParams = {}

        for (const key in defaultState) {
          if (!Array.isArray(defaultState[key])) {
            params[key] = state[key]
            continue
          }

          const arrayValue = []
          for (const element of state[key]) {
            arrayValue.push(element)
          }
          params[key] = arrayValue
        }

        return params
      }
    },
    activeFiltersCountByField: function (state): (field: string) => number {
      return (field: string): number => {
        if (field === 'is_static_page' || field === 'force_page_change' || field === 'page' || field === 'rows' || field === 'only_object_ids' || field === 'only_lastminute_objects') {
          return 0
        }

        if (!Array.isArray(state?.[field]) && state[field] !== defaultState[field]) {
          return 1
        }
        if (!Array.isArray(state?.[field])) {
          return 0
        }

        if (state?.[field].length > 0 && (field === 'haustyp' || field === 'ausstattung')) {
          return state?.[field].length ?? 0
        }
        if (state?.[field].length > 0) {
          return 1
        }

        return 0
      }
    },
    activeFilters: function (state): SearchParams {
      const params: SearchParams = {}
      for (const key in defaultState) {
        if (!this.activeFiltersCountByField(key)) {
          continue
        }
        params[key] = state[key]
      }
      return params
    },
    activeFiltersCountForAllFields: function (): number {
      let count = 0
      for (const key in defaultState) {
        count = count + this.activeFiltersCountByField(key)
      }
      return count
    },
    activeFiltersCountForAllFieldsExceptOrtVonBisPersonen: function (): number {
      let count = 0
      const excludedKeys = ['ort', 'von', 'bis', 'variabilitaet', 'personen', 'limits', 'order', 'serp_highlight']
      for (const key in defaultState) {
        if (excludedKeys.includes(key)) {
          continue
        }
        count = count + this.activeFiltersCountByField(key)
      }
      return count
    },
    activeFiltersCountForMultipleFields: function (state): (fields: string[], ausstattungItems: string[]) => number {
      return (fields: string[], ausstattungItems: string[]): number => {
        let count = 0
        for (const field of fields) {
          count = count + state.activeFiltersCountByField(field)
        }
        for (const ausstattungItem of ausstattungItems) {
          if (Array.isArray(state?.ausstattung) && state?.ausstattung.contains(ausstattungItem)) {
            count++
          }
        }
        return count
      }
    }
  },
  actions: {
    resetToDefaultStateExceptOrtVonBisPersonen (forcePageChange: boolean = true, onlyLastminuteObjects: boolean = false): void {
      const deepClone = defaultStateDeepClone()

      deepClone.ort = this.$state.ort
      deepClone.von = this.$state.von
      deepClone.bis = this.$state.bis
      deepClone.variabilitaet = this.$state.variabilitaet
      deepClone.personen = this.$state.personen
      deepClone.order = this.$state.order

      this.replaceWithNewSearchParams(deepClone, forcePageChange, onlyLastminuteObjects)
    },
    resetToDefaultState (forcePageChange: boolean = true, onlyLastminuteObjects: boolean = false): void {
      this.replaceWithNewSearchParams(defaultStateDeepClone(), forcePageChange, onlyLastminuteObjects)
    },
    replaceWithNewSearchParams (newSearchParams: SearchParams|undefined, forcePageChange: boolean = true, onlyLastminuteObjects: boolean = false): void {
      if (
        newSearchParams === undefined ||
        typeof newSearchParams !== 'object' ||
        Object.keys(newSearchParams).length === 0
      ) {
        return
      }

      const newState: SearchParams = defaultStateDeepClone()
      newState.force_page_change = forcePageChange
      newState.page = this.$state.page
      const newPage = forcePageChange ? this.$state.page : null

      // Add new params
      for (const key in newSearchParams) {
        if (!Object.prototype.hasOwnProperty.call(newState, key)) {
          continue
        }
        if (newSearchParams?.[key] === undefined) {
          continue
        }

        if (!Array.isArray(newSearchParams[key])) {
          newState[key] = newSearchParams[key]
          continue
        }
        newState[key] = []
        for (const item of newSearchParams[key]) {
          newState[key].push(key === 'ausstattung' ? Number(item) : item)
        }
      }

      newState.force_page_change = forcePageChange
      if (newPage !== null) {
        newState.page = newPage
      }

      newState.only_lastminute_objects = onlyLastminuteObjects

      this.$patch(newState)
    }
  }
})
